html {
  font-size: 10px;
}
body {
  font-size: 16px;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif, 'Apple Color';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h4 {
  margin-top: 20px;
}
a {
  transition: all 200ms;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.jumbotron {
  /*background-color: #eb691c;
  color: white;*/
  background-color: #f8f8f8;
  color: #eb691c;
  font-weight: bold;
}

.jumbotron img {
  margin-left: 20px;
  vertical-align: bottom;
}

.jumbotron a {
  margin-left: 20px;
  font-weight: 100;
  font-size: 85%;
  color: #eb691c;
}
.contain {
  background-color: white;
  border-radius: 3px;
  padding: 20px;
  max-width: 900px;
  margin: auto;
}
.docs {
  background-color: #eb691c;
  margin-top: 20px;
  padding: 30px;
}
.examples {
  position: relative;
  /*max-width: 1200px;*/
  margin: 0 auto;
}
.example {
  font-size: 14px;
  padding: 0 40px;
  min-height: calc(100vh - 100px);
  min-height: max-content;
  height: calc(100vh - 100px);
  width: 100%;
  margin: auto;
}
.example,
.example > * {
  display: flex;
  flex-direction: column;
}
.example .rbc-calendar {
  flex: 1;
  min-height: 900px;
  margin-bottom:20px;
}
.examples--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.examples--list > li {
  text-align: center;
}
.examples--list a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 1.4rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
}
.examples--list a:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
}
.examples--list a:hover:after {
  background-color: #3174ad;
}
.section {
  margin-bottom: 20px;
}
aside {
  margin-bottom: 40px;
}
h3 > a > code,
h4 > a > code {
  color: #3174ad;
  background: none;
  padding: 0;
}
.examples--header {
  margin: 0 40px;
  text-align: center;
}
.dropdown--toggle {
  font-size: 18px;
  font-weight: 600;
  border-radius: 3px;
  transition: all 200ms;
}
.dropdown--toggle,
.dropdown--toggle:hover,
.dropdown--toggle:focus,
.dropdown--toggle:active {
  color: #ad3173;
  text-decoration: none;
}
.dropdown--toggle:hover,
.dropdown--toggle:focus,
.dropdown--toggle:active {
  color: #992b66;
  border: 1px solid #ad3173;
  text-decoration: none;
}
.examples--view-source {
  font-size: 80%;
}
.callout {
  border-left: 4px solid #3174ad;
  padding: 10px;
  color: #265985;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 0;
}
pre {
  border-radius: 8px;
  border: none;
}
pre.shape-prop {
  border: none;
}
code {
  color: #555;
  background-color: rgba(0, 0, 0, 0.04);
}
.playgroundStage,
.cm-s-neo.CodeMirror {
  background-color: #f4f4f4;
  height: auto;
  min-height: 75px;
}
.CodeMirror {
  font-size: 12px;
}
.cm-s-neo div.CodeMirror-cursor {
  border-left: 1px solid #9b9da2;
}
.cm-s-neo .CodeMirror-linenumber {
  color: #ccc;
}
.cm-s-neo .cm-atom,
.cm-s-neo .cm-number {
  color: #905;
}
.prop-table {
  font-size: 14 px;
}
.playgroundStage {
  padding: 15px 0 15px 15px;
}
.playground.collapsableCode .playgroundCode {
  height: 0;
  overflow: hidden;
}
.playground.collapsableCode .playgroundCode.expandedCode {
  height: auto;
}
.playgroundPreview {
  position: relative;
  padding: 40px 15px 15px 15px;
}
.playgroundPreview:before {
  position: absolute;
  top: 3px;
  left: 7px;
  color: #959595;
  border-bottom: 1px solid #eee;
  padding: 0 3px;
  content: 'Result';
}
.playground {
  position: relative;
  margin: 0;
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
}
.playgroundCode,
.playgroundPreview {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.playgroundToggleCodeBar {
  padding: 1px;
  border-top: 1px solid #ccc;
}
.playgroundToggleCodeLink {
  color: #333;
  background-color: #ccc;
  margin-top: 1px;
  margin-left: -1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 3px 5px;
}
.playgroundToggleCodeLink:hover,
.playgroundToggleCodeLink:focus {
  color: black;
}
.anchor,
.anchor:hover,
.anchor:active,
.anchor:focus {
  color: black;
  text-decoration: none;
  position: relative;
}
.anchor-icon {
  font-size: 90%;
  padding-top: 0.1em;
  position: absolute;
  left: -0.8em;
  opacity: 0;
}
h1:hover .anchor-icon,
h1 a:focus .anchor-icon,
h2:hover .anchor-icon,
h2 a:focus .anchor-icon,
h3:hover .anchor-icon,
h3 a:focus .anchor-icon,
h4:hover .anchor-icon,
h4 a:focus .anchor-icon {
  opacity: 0.5;
}
.special-day {
  background-color: #fec;
}
.card {
  background-color: white;
  border: 0;
  padding: 24px;
  border-radius: 2px;
  margin-bottom: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.autonomieActivity .rbc-event{
  background-color: #e3d075;
  border: 1px solid #e3d075;
}

.socioeducatifActivity .rbc-event{
  background-color: #ff671d;
  border: 1px solid #ff671d;
}

.projetprofessionelActivity .rbc-event{
  background-color: #005aa4;
  border: 1px solid #005aa4;
}

.larelationActivity .rbc-event {
  background-color: #951b81;
  border: 1px solid #951b81;
}

.fermetureActivity .rbc-event {
  background-color: #858585;
  border: 1px solid #858585;
}

.lessoinspoursoiActivity .rbc-event {
  background-color: #fbb914;
  border: 1px solid #fbb914;
}

.boostersacandidatureActivity .rbc-event {
  background-color: #008cff;
  border: 1px solid #008cff;
}

.individuelleActivity .rbc-event {
  background-color: #df55c7;
  border: 1px solid #df55c7;
}

.insertionsocialeActivity .rbc-event {
  background-color: #3aaa35;
  border: 1px solid #3aaa35;
}

.rbc-event-content .event-place a {
  line-height:2;
  font-size: 12px;
  color: white;
}

.rbc-event-content .event-place a:hover {
  text-decoration: underline;
}

.rbc-event-content .event-annotation {
  line-height:2;
  margin-top:5px;
  margin-bottom:5px;
  font-size: 10px;
  text-align: center;
}

.rbc-event-content .event-participants {
  line-height:2;
  text-align: right;
  font-style: italic;
  font-size: 12px;
}

.pedagogyRow {
  line-height: 3em;
}

.is-registered-true .rbc-event {
  border: 5px solid red;
}

.uploadedFile {
  text-align: center;
  color: blue;
  margin-top: 10px;
}

.downloadable {
  cursor: pointer;
}

.react-autosuggest__suggestions-container--open{
  position: absolute;
  z-index:10;
  background-color: white;
  width: 100%;
}

.react-autosuggest__suggestions-list{
  list-style-type: none;
  padding-left: 3px;
}

#allPedagogy{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.rowPedagogy{
  margin-bottom: 20px;
}

.starChecked {
  color: orange;
  margin-right: 5px;
}

.rbc-time-slot .rbc-event {
  height: 5.55556%;
  text-align: center;
  display: block;
  border-radius: 0px;
  border: 0px;
}

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
  overflow: hidden;
}

.is-cooperator-true .rbc-event{
  border: 5px solid red;
}

.rbc-event-label {
  display: none;
}

.new-rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
  font-size: 80%;
  margin-bottom:3px;
}

.navbar {
  margin-bottom: 0px;
}

.pillarTitle {
  font-size: 0.9em;
}

.pillarDescription {
  font-size: 0.9em;
}


#alert {
  z-index: 100000;
}
